import { isRouteErrorResponse, useRouteError } from '@remix-run/react'
import { SharedLayout } from './shared-layout'
import { SupportedLanguages } from '~/config/i18n'
import { Footer } from '~/components-v2/footer/footer'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import type { ErrorBoundaryComponent } from '@remix-run/react/dist/routeModules'
import { H1, H2, Stack } from '~/components-v2'

const handleError = (error: unknown) => {
  if (isRouteErrorResponse(error) && error.status === 404)
    return {
      title: 'Not Found',
      message: 'The page you are looking for does not appear to exist.',
    }

  if (error instanceof Error)
    return {
      title: "We're sorry, an error occured",
      message: error.message,
    }

  return {
    title: "We're sorry, an error occured",
    message: 'An unknown error occurred.',
  }
}

export const ErrorBoundary: ErrorBoundaryComponent = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  const { title, message } = handleError(error)

  return (
    <SharedLayout lang={SupportedLanguages.English}>
      <main>
        <Stack as="section" gap={6} className="error-boundary">
          <H1 fontWeight={600} fontSize="xxxl">
            {title}
          </H1>
          {message && <H2 fontSize="xxl">{message}</H2>}
        </Stack>
      </main>
      <Footer />
    </SharedLayout>
  )
}
