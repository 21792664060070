import {
  Links,
  Meta,
  Scripts,
  ScrollRestoration,
  useLocation,
} from '@remix-run/react'
import clsx from 'clsx'
import type { ReactNode } from 'react'
import { CANONICAL_HOSTNAME } from '~/config'
import { SupportedLanguages } from '~/config/i18n'

export const SharedLayout = ({
  children,
  lang = SupportedLanguages.English,
}: {
  children: ReactNode
  lang: SupportedLanguages
}) => {
  const { pathname } = useLocation()

  return (
    <html lang={lang}>
      <head>
        <Meta />
        <Links />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <link
          rel="canonical"
          href={`https://${CANONICAL_HOSTNAME}${pathname}`}
        />
      </head>
      <body
        className={clsx(
          pathname === '/' ? 'home' : 'page',
          pathname.split('/'),
        )}
      >
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}
